import React,{Fragment, useState} from 'react'
import axios from "axios";
import {Link, navigate} from 'gatsby'
import Seo from "../../components/seo/seo";
import AOS from "aos";
import TextBox from "../../components/inputs/Textbox";
import PhoneNumber from "../../components/inputs/PhoneNumber";
//import CheckBox from "../../components/inputs/CheckBox";
// import SelectBox from "../../components/inputs/SelectBox";
import Loading from "../../components/common/Loading";
import ErrorMessage from "../../components/common/ErrorMessage";
// import countryList from '../../components/json/countryList'
// import industryList from '../../components/json/businessList'
import logo from '../../assets/img/virsat-logo-light.svg'
import { StaticImage } from "gatsby-plugin-image";
// import downloadPDF from "../../components/utils/downloadPDF"

function FreeCourseRegistration({location}) {
  const [sending, setSending] = useState(false);
  const [errors, setErrors] = useState({});
//   const [success, setSuccess] = useState(false);

  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [txtValues, setTxtValues] = useState({
    name: "",
    company: "",
    email: "",
    mobile: "",
    course1: 1,
    course2: 1,
    course3: 1,
    course4: 1,
    course5: 1,
    course6: 1,
  });

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    const checkbox_value = checked ? 1 : 0
    setTxtValues({ ...txtValues, [name]: type === "checkbox" ? checkbox_value : value });
  };

  const sendData = async () => {
    setSending(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // const data = {...txtValues, ...selectValues}
    const data = {...txtValues, free: "Yes" }

    const body = JSON.stringify({ ...data});

    try {
      const res = await axios.post(
        `${process.env.GATSBY_API_URL}/register-training-course`,
        body,
        config
      );

      if (res.data.status) {
        resetValues()
        navigate("/lp/thank-you-form/", {state: { details: "The form was successfully submitted. If you're lucky, one of our sales representative will get in touch with you soon." }})
      } else {
        setErrors(res.data.errors);
      }
      
      setSending(false);
    } catch (err) {
      setSending(false);
      setErrors({send_mail:1});
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendData();
  };

  const handleClose = () => {
    setErrors({});
  };

  const resetValues = ()=> {
    setErrors({});
    setTxtValues({
      name: "",
      company: "",
      email: "",
    });
  }

  return (
    <Fragment>
      {sending && <Loading message="Please wait while we are processing your request." />}
      {errors.send_mail && <ErrorMessage handleClose={handleClose} />}
      <Seo
        title='FREE VR Course Registration | VIRSAT - Virtual Reality Safety Training'
        description='Register your interest. We have courses available for your.'
      />
      
      <div className="main-container newsletter-lp">
        <div className="newsletterlp-bg">
          <StaticImage
            src='../../assets/img/pages/newsletter/virsat-newsletter-background.jpg'
            alt='VIRSAT VR Courses Background'
            placeholder='blurred'
            objectFit
            quality="100"
          />
        </div>
        <div className="row training-courses" data-aos='zoom-in' data-aos-duration='600'>
        <div className="column show-overflow">
                <div className="form-bg-container">
                <StaticImage
                    src='../../assets/img/pages/training-courses/VR-training-registration-photo.jpg'
                    alt='VR Form Background'
                    placeholder='blurred'
                    objectFit
                    quality="100"
                    style={{borderRadius:"15px"}}
                    imgStyle={{borderRadius:"15px"}}
                />
                </div>
                
                <div className="logo-container">
                <Link to="/">
                    <img src={logo} alt="VIRSAT Logo" title="VIRSAT Logo"/>
                </Link>
                </div>
                <div className="form-container training-courses">
                <h1 className="main-title small light">VR Course Registration</h1>
                <p className="light description justify">Please fill up the form below to register for the free training.</p>
                <form action='' method='post' onSubmit={handleSubmit}>
                    <div className='label-container'>
                    <TextBox
                        type='text'
                        id='name'
                        name='name'
                        placeholder='Your Name*'
                        txtBoxClass='regular-text'
                        onChange={handleInputChange}
                        value={txtValues.name}
                        required
                        error={errors.name}
                    />
                    {errors.name && (
                        <div className='field-error secondary-color'>{errors.name[0]}</div>
                    )}
                    </div>
                    <div className='label-container'>
                    <TextBox
                        type='text'
                        id='company'
                        name='company'
                        placeholder='Company Name*'
                        txtBoxClass='regular-text'
                        onChange={handleInputChange}
                        value={txtValues.company}
                        required
                        error={errors.company}
                    />
                    {errors.company && (
                        <div className='field-error secondary-color'>{errors.company[0]}</div>
                    )}
                    </div>
                    <div className='label-container'>
                    <TextBox
                        type='email'
                        id='email'
                        name='email'
                        placeholder='Company Email*'
                        txtBoxClass='regular-text'
                        onChange={handleInputChange}
                        value={txtValues.email}
                        required
                        error={errors.email}
                    />
                    {errors.email && (
                        <div className='field-error secondary-color'>{errors.email[0]}</div>
                    )}
                    </div>
                    <div className='label-container'>
                        <PhoneNumber
                        name='mobile'
                        id='mobile'
                        value={txtValues.mobile}
                        onChange={(mobile) =>
                            setTxtValues({ ...txtValues, mobile: mobile })
                        }
                        txtBoxClass='regular-text'
                        />
                        {errors.mobile && (
                        <div className='field-error'>{errors.mobile[0]}</div>
                        )}
                    </div>
                    <div>
                      <h3 style={{"color": "white", "marginBottom": "10px"}}>Courses offered:</h3>
                    </div>
                    <div className='courses-offered-list'>
                      <div>
                        <div className="checkbox-container" style={{"marginBottom": "10px"}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                          </svg>
                          <label htmlFor="course1" className="light small after-checkbox">Virtual Reality Road Safety Awareness</label>
                        </div>
                        <div className="checkbox-container" style={{"marginBottom": "10px"}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                          </svg>
                          <label htmlFor="course2" className="light small after-checkbox">Virtual Reallity Driving on Blacktop Roads</label>
                        </div>
                        <div className="checkbox-container" style={{"marginBottom": "10px"}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                          </svg>
                          <label htmlFor="course3" className="light small after-checkbox">Virtual Reality Driving on Graded Roads</label>
                        </div>
                      </div>
                      <div>
                        <div className="checkbox-container" style={{"marginBottom": "10px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        <label htmlFor="course4" className="light small after-checkbox">Driving Simulator on Graded Roads</label>
                      </div>
                      <div className="checkbox-container" style={{"marginBottom": "10px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        <label htmlFor="course4" className="light small after-checkbox">Life Saving Rules ADNOC standard</label>
                      </div>
                      <div className="checkbox-container" style={{"marginBottom": "10px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        <label htmlFor="course4" className="light small after-checkbox">Life Saving Rules IOGP standard</label>
                      </div>
                      </div>
                    </div>
                    
                    <button type='submit' className="full-width" style={{marginTop:'10px'}}>Submit</button>
                </form>
                </div>
            </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FreeCourseRegistration
